import { Prospect, ProspectToClientDto, UpdateProspectDto } from "../models/prospect.model";
import { http } from "./axios";

export class ProspectService {
  public static importProspect(): void {}

  public static getProspects(siteId: number): Promise<Prospect[]> {
    return http.get(`/prospects/site/${siteId}`);
  }

  public static prospectToClient(payload:ProspectToClientDto): Promise<Prospect> {
    return http.post(`/prospects/${payload.prospectId}/site/${payload.siteId}/to-client`, payload);
  }

  public static checkIfMeterNumberExist(meterNumber:string): Promise<boolean> {
    return http.get(`/meters/meter-number-exist/${meterNumber}`);
  }

  public static checkIfHouseIdExist(prospectId:number, houseId:string): Promise<boolean> {
    return http.get(`/prospects/${prospectId}/house-id-exist/${houseId}`);
  }

  public static getProspectBySiteIdAndProspectId(siteId:number, prospectId: number,): Promise<Prospect> {
    return http.get(`/prospects/${prospectId}/site/${siteId}`);
  }

  public static getDistrictBySiteId(siteId:number): Promise<any[]> {
    return http.get(`/districts/${siteId}/site`);
  }

  public static updateProspect(payload:UpdateProspectDto): Promise<Prospect> {
    return http.put(`/prospects/${payload.id}/site/${payload.siteId}`, payload);
  }
}
